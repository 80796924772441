

export default class ZJAdminDataManager {
    public static loginStatus: string = "0" // "0"  "1"
    public static loginToken: string = ""
    private static adminTokenKey = "admin_token_key"
    private static adminTokenSetTimeKey = "admin_token_set_time_key"

    public static getAdminToken() {
        let token = localStorage.getItem(this.adminTokenKey)
        return token;
    }
    public static saveAdminToken(token: string) {
        if (token && token.length > 0) {
            localStorage.setItem(this.adminTokenKey, token)
        }
    }
    public static removeAdminToken() {
        localStorage.removeItem(this.adminTokenKey)
    }

}
