

export default class LCUserTokenManager {
    public static loginStatus: string = "0" // "0"  "1"
    public static loginToken: string = ""
    private static userTokenKey = "user_token_key"
    private static userTokenCreateTimeKey = "user_token_create_time_key"
    private static userTokenMaxAgeKey = "user_token_max_age_key"
    // user session的过期时间是3天
    private static UserSessionMaxAge = 60 * 60 * 24 * 3

    public static getUserToken() {
        let token = localStorage.getItem(this.userTokenKey)
        let maxAge = localStorage.getItem(this.userTokenMaxAgeKey)
        let createTime = localStorage.getItem(this.userTokenCreateTimeKey)
        if (token && token.length > 0
            && maxAge && maxAge.length > 0
            && createTime && createTime.length > 0) {
            let nowTime = Date.now() / 1000;
            let createToNow = nowTime - parseInt(createTime)
            if (createToNow < parseInt(maxAge)) {
                return token
            }
        }
        document.cookie = "JSESSIONID=; max-age=0";
        return null;
    }
    public static saveUserToken(token: string, maxAge: string) {
        if (token && token.length > 0 && maxAge && maxAge.length > 0) {
            document.cookie = "JSESSIONID=" + token + "; max-age=" + maxAge;
            let currentTime = Date.now() / 1000;
            localStorage.setItem(this.userTokenKey, token)
            localStorage.setItem(this.userTokenCreateTimeKey, currentTime + "")
            localStorage.setItem(this.userTokenMaxAgeKey, maxAge ? maxAge : "")
        }
    }
    public static removeUserToken() {
        localStorage.removeItem(this.userTokenKey)
    }

    public static userLoginState() {
        let token = localStorage.getItem(this.userTokenKey)
        if (token && token.length > 0) {
            return true;
        }
        return false;
    }
}